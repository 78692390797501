<template>
  <div class="">
    <div class="pond-status">
      <!-- <img class="" src="@/assets/images/frameM.png" alt="" /> -->
      <img class="" src="@/assets/images/new-pond.png" alt="" />
      <div
        id="pond1"
        class="pond pond-1-overlay"
        :class="{ 'pond-error': pondOneError }"
      ></div>
      <div
        id="pond2"
        class="pond pond-2-overlay"
        :class="{ 'pond-error': pondTwoError }"
      ></div>
      <div
        v-for="(item, index) in ponds"
        :class="'pond-single pond-' + (index + 1)"
        :key="index"
      >
        <div class="pond-name">{{ item.name }}</div>
        <div class="pond-icons-wpr">
          <!-- controller icons -->
          <div class="icon-wpr controller-icons">
            <img
              v-if="item.controllerStatus === 0"
              src="@/assets/icons/controller-normal.png"
              alt=""
            />
            <img
              v-else-if="item.deviceList.length === 0"
              src="@/assets/icons/controller-alert.png"
              alt=""
            />
            <img v-else src="@/assets/icons/controller-error.png" alt="" />
          </div>
          <!-- oxygen pump icons -->
          <div
            class=""
            v-for="(oxygenMachine, index) in item.deviceList[0].detailList"
            :key="index"
          >
            <div class="">
              <div class="icon-wpr controller-icons">
                <img
                  v-if="oxygenMachine.relayOnlineStatus === 0"
                  src="@/assets/icons/fan-error.gif"
                  alt="增氧掉线动图"
                />
                <img
                  v-else-if="oxygenMachine.openStatus === 0"
                  src="@/assets/icons/fan-normal-img.jpg"
                  alt="增氧正常静态图片"
                />
                <img
                  v-else-if="oxygenMachine.isAlert === true"
                  src="@/assets/icons/fan-alert.gif"
                  alt="增氧告警动态图片"
                />
                <img
                  v-else-if="oxygenMachine.openStatus === 1"
                  src="@/assets/icons/fan-normal.gif"
                  alt="增氧正常动态图片"
                />
              </div>
            </div>
          </div>

          <!-- sensor status  0 正常， 1 异常-->
          <div class="icon-wpr controller-icons">
            <img
              v-if="item.sensorStatus === 0"
              src="@/assets/icons/sensor-normal.png"
              alt=""
            />
            <img v-else src="@/assets/icons/sensor-alert.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPondStatusApi, userId } from "@/api/modules/operation";
export default {
  data() {
    return {
      interval: null, // 定时器
      ponds: [],
      pondOneError: false,
      pondTwoError: false,
    };
  },
  mounted() {
    if (userId == 267) {
      this.fetchData267(); // 立即执行一次
      this.interval = setInterval(this.fetchData267, 10000); // 然后每15秒执行一次
    }
    if (userId == 217) {
      this.fetchData217(); // 立即执行一次
      this.interval = setInterval(this.fetchData217, 10000); // 然后每15秒执行一次
    }
  },
  beforeDestroy() {
    clearInterval(this.interval); // 清除定时器
  },
  methods: {
    async fetchData217() {
      // reset pond error class
      this.pondOneError = false;
      this.pondTwoError = false;
      try {
        const res = await getPondStatusApi();
        // const firstFour = res.data.slice(0, 4); // 暂时只要前 4 个鱼塘 数据放在图片上
        const firstFour = res.data.filter((pond) => {
          return pond.id === 652 || pond.id === 648 ? pond : null;
        }); // 暂时只要前 4 个鱼塘 数据放在图片上
        console.log("暂时只要前 4 个鱼塘 数据放在图片上", firstFour);
        // for i 遍历塘口
        const pondListLength = firstFour.length; // 遍历塘口 目前只有4个
        for (let i = 0; i < pondListLength; i++) {
          // 首先给控制器一个正常状态，在遍历过程中如果有错误直接跳过loop
          // 这样的话保证了，如果从机里面但凡有一个异常，在界面就会显示异常的图表
          firstFour[i].controllerStatus = 0;
          // for j 遍历控制器 List 获取3种图标状态
          for (let j = 0; j < firstFour[i].deviceList.length; j++) {
            // for k 遍历控制器下的接口
            if (firstFour[i].deviceList.length !== 0) {
              const detailListLength =
                firstFour[i].deviceList[j].detailList.length;
              for (let k = 0; k < detailListLength; k++) {
                const machine = firstFour[i].deviceList[j].detailList[k];
                // 控制器状态 - relayOnlineStatus 1 在线 0 离线
                const machineOnlineStatus = machine.relayOnlineStatus === 1;
                if (!machineOnlineStatus) {
                  firstFour[i].controllerStatus = 2; // 0 图标显示蓝色，正常 2 图标显示红色，离线
                  if (i === 0) {
                    this.pondOneError = true;
                  } else if (i === 1) {
                    this.pondTwoError = true;
                  }
                }
              }
            }
          }
        }
        this.ponds = firstFour;
      } catch (error) {
        // console.log(error);
      }
    },
    async fetchData267() {
      // reset pond error class
      this.pondOneError = false;
      this.pondTwoError = false;
      try {
        const res = await getPondStatusApi();
        // const firstFour = res.data.slice(0, 4); // 暂时只要前 4 个鱼塘 数据放在图片上
        const firstFour = res.data.filter((pond) => {
          return pond.id === 701 || pond.id === 702 ? pond : null;
        }); // 暂时只要前 4 个鱼塘 数据放在图片上
        console.log("暂时只要前 4 个鱼塘 数据放在图片上", firstFour);
        // for i 遍历塘口
        const pondListLength = firstFour.length; // 遍历塘口 目前只有4个
        for (let i = 0; i < pondListLength; i++) {
          // 首先给控制器一个正常状态，在遍历过程中如果有错误直接跳过loop
          // 这样的话保证了，如果从机里面但凡有一个异常，在界面就会显示异常的图表
          firstFour[i].controllerStatus = 0;
          // for j 遍历控制器 List 获取3种图标状态
          for (let j = 0; j < firstFour[i].deviceList.length; j++) {
            // for k 遍历控制器下的接口
            if (firstFour[i].deviceList.length !== 0) {
              const detailListLength =
                firstFour[i].deviceList[j].detailList.length;
              for (let k = 0; k < detailListLength; k++) {
                const machine = firstFour[i].deviceList[j].detailList[k];
                // 控制器状态 - relayOnlineStatus 1 在线 0 离线
                const machineOnlineStatus = machine.relayOnlineStatus === 1;
                if (!machineOnlineStatus) {
                  firstFour[i].controllerStatus = 2; // 0 图标显示蓝色，正常 2 图标显示红色，离线
                  if (i === 0) {
                    this.pondOneError = true;
                  } else if (i === 1) {
                    this.pondTwoError = true;
                  }
                }
              }
            }
          }
        }
        this.ponds = firstFour;
      } catch (error) {
        // console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pond-status {
  width: 100%;
  height: 360px;
  padding: 0px 2px;
  position: relative;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.pond-name {
  color: yellow;
  font-size: 20px;
  margin-bottom: 10px;
}
.pond-single {
  padding: 5px 5px;
  border-radius: 5px;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  // background-color: rgba(255, 255, 255, 0.8);
}
.pond-1 {
  left: 26%;
  top: 26%;
}
.pond-2 {
  right: 12%;
  top: 23%;
}
.pond-3 {
  left: 15%;
  top: 65%;
}
.pond-4 {
  right: 15%;
  top: 65%;
}
.pond-icons-wpr {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 160px;
}

.icon-wpr {
  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
}
.pond {
  position: absolute;
  width: 100%;
  height: 100%;
}
@keyframes blink {
  0%,
  100% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }
}

.pond-error {
  background-color: red;
  opacity: 0.75;
  animation: blink 1s infinite; /* 1s表示闪烁周期为1秒，infinite表示动画无限次数播放 */
}
.pond-1-overlay {
  top: 5%;
  left: 35px;
  -webkit-clip-path: path("M1.5 145.5L347.5 1.5L448 65L76.5 237L1.5 145.5Z");
  clip-path: path("M1.5 145.5L347.5 1.5L448 65L76.5 237L1.5 145.5Z");
  transform: scale(0.75);
}
.pond-2-overlay {
  top: 34px;
  left: 434px;
  -webkit-clip-path: path("M2 47.5L89 1L281 100.5L195 157.5L2 47.5Z");
  clip-path: path("M2 47.5L89 1L281 100.5L195 157.5L2 47.5Z");
  transform: scale(0.75);
}
</style>
