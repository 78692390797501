<template>
  <div>
    <dv-border-box-12>
      <e-chart
        :options="options"
        id="box"
        height="350px"
        width="100%"
      ></e-chart>
    </dv-border-box-12>
  </div>
</template>

<script>
// 塘口-渔机种类
import EChart from "@/common/echart/index.vue";
import { getPondMachines } from "@/api/modules/operation";
// const rawData = [
//   {
//     fishpondId: 711,
//     fishpondTypeList: [
//       { fishpondId: 711, deviceType: 0, learningStatus: 0 },
//       { fishpondId: 711, deviceType: 1, learningStatus: 0 },
//       { fishpondId: 711, deviceType: 2, learningStatus: 0 },
//       { fishpondId: 711, deviceType: 3, learningStatus: 0 },
//     ],
//   },
//   {
//     fishpondId: 722,
//     fishpondTypeList: [
//       { fishpondId: 722, deviceType: 0, learningStatus: 0 },
//       { fishpondId: 722, deviceType: 4, learningStatus: 0 },
//       { fishpondId: 722, deviceType: 2, learningStatus: 0 },
//       { fishpondId: 722, deviceType: 1, learningStatus: 0 },
//       { fishpondId: 722, deviceType: 6, learningStatus: 0 },
//     ],
//   },
// ];

const deviceNames = [
  "增氧机",
  "划水机",
  "水泵",
  "投料机",
  "微孔机",
  "罗茨机",
  "其他",
];

// const seriesData = deviceNames.map((item, index) => {
//   let hasDevice = false; // 标记是否有该设备
//   const eachDeviceCountForCurrentPondArray = new Array(rawData.length);
//   eachDeviceCountForCurrentPondArray.fill(0);
//   for (let i = 0; i < rawData.length; i++) {
//     rawData[i].fishpondTypeList.forEach((device) => {
//       if (device.deviceType === index) {
//         hasDevice = true;
//         eachDeviceCountForCurrentPondArray[i] += 1;
//       }
//     });
//   }
//   if (!hasDevice) return null;
//   return {
//     name: item,
//     type: "bar",
//     stack: "total",
//     emphasis: {
//       focus: "series",
//     },
//     data: eachDeviceCountForCurrentPondArray,
//   };
// });

export default {
  components: { EChart },
  data() {
    return {
      options: {},
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      try {
        const res = await getPondMachines();
        // console.log("塘口--渔机种类", res);
        const { data } = res;
        const firstTen = data.slice(0, 10);
        const seriesData = this.filterSeriesData(firstTen);
        const yAxisPondName = firstTen.map((item, index) => {
          // return item.fishpondName; // TODO: 有真实数据以后吧这个打开
          const fakeName = [
            "鲫鱼",
            "鲤鱼",
            "草鱼",
            "鲢鱼",
            "鲈鱼",
            "鲷鱼",
            "鳗鱼",
            "鳜鱼",
            "鲟鱼",
            "鳊鱼",
          ];
          return fakeName[index] + "塘";
        });
        this.options = {
          title: {
            text: "塘口-渔机种类",
            top: "3%",
            left: "3%",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              // Use axis to trigger tooltip
              type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {
            orient: "horizontal",
            top: "5%",
            left: "35%",
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
          },
          yAxis: {
            type: "category",
            data: yAxisPondName,
          },
          series: seriesData,
        };
      } catch (error) {
        console.log(error);
      }
    },
    filterSeriesData(rawData) {
      return deviceNames.map((item, index) => {
        let hasDevice = false; // 标记是否有该设备
        const eachDeviceCountForCurrentPondArray = new Array(rawData.length);
        eachDeviceCountForCurrentPondArray.fill(0);
        for (let i = 0; i < rawData.length; i++) {
          rawData[i].fishpondTypeList.forEach((device) => {
            if (device.deviceType === index) {
              hasDevice = true;
              eachDeviceCountForCurrentPondArray[i] += 1;
            }
          });
        }
        if (!hasDevice) return null;
        return {
          name: item,
          type: "bar",
          stack: "total",
          emphasis: {
            focus: "series",
          },
          data: eachDeviceCountForCurrentPondArray,
        };
      });
    },
  },
};
</script>
