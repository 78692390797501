<template>
  <div>
    <dv-border-box-12 class="relative">
      <e-chart
        :options="options"
        id="box"
        height="350px"
        width="100%"
      ></e-chart>
      <!-- <div class="data-sets">
        <div class="" v-for="(item, index) in dataList" :key="index">
          <div class="">{{ index }}</div>
        </div>
      </div> -->
    </dv-border-box-12>
  </div>
</template>

<script>
import EChart from "@/common/echart/index.vue";
import { getAllPondMachineStatus } from "@/api/modules/operation";

export default {
  components: { EChart },
  data() {
    return {
      options: {},
      dataList: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const res = await getAllPondMachineStatus();
        const { data } = res;
        const rawData = data.historyList[0].pondHistoryList;
        this.dataList = data.historyList;
        console.log("各测点数据", rawData);

        // 1. Extract and format the x-axis data (createTime) to "MM-DD"
        const xData = rawData.map((item) => item.createTime.slice(5, 10));

        // 2. Extract y-axis data for each of the desired properties
        const sensorTData = rawData.map((item) => item.sensorT);
        const sensorYData = rawData.map((item) => item.sensorY);
        const phData = rawData.map((item) => item.ph);

        // 3. Setup ECharts options
        const option = {
          legend: {
            data: ["水温", "溶氧", "pH值"],
            top: "5%",
            left: "40%",
          },
          grid: {
            top: "15%",
            bottom: "10%",
          },
          xAxis: {
            type: "category",
            data: xData,
          },
          yAxis: {
            type: "value",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "line",
            },
            formatter: function (params) {
              let result = params[0].axisValue + "<br>"; // This will display the date
              params.forEach((param) => {
                result += `${param.seriesName}: ${param.value}<br>`;
              });
              return result;
            },
          },
          series: [
            {
              name: "水温",
              type: "line",
              smooth: true,
              data: sensorTData,
            },
            {
              name: "溶氧",
              type: "line",
              smooth: true,
              data: sensorYData,
            },
            {
              name: "pH值",
              type: "line",
              smooth: true,
              data: phData,
            },
          ],
        };

        this.options = option;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    updateChart() {},
  },
};
</script>

<style scoped>
.data-sets {
  position: absolute;
  top: 5%;
  right: 5%;
}
</style>
