<template>
  <div>
    <dv-border-box-12 class="relative">
      <div class="title">基塘一塘口统计</div>
      <e-chart
        :options="options"
        id="box"
        height="350px"
        width="100%"
      ></e-chart>
    </dv-border-box-12>
  </div>
</template>

<script>
import EChart from "@/common/echart/index.vue";
import { getPondMachineCount } from "@/api/modules/operation";

export default {
  components: { EChart },
  data() {
    return {
      options: {},
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const result = await getPondMachineCount();
        console.log("基塘-塘口统计 getPondMachineCount ", result);
        const { data } = result;
        const ponds = data.slice(0, 10).map((pond) => {
          return {
            value: parseInt(pond.mainji),
            name: pond.name,
          };
        });
        // console.log(ponds);
        const totalArea = ponds.reduce((acc, item) => {
          let val = item.value;
          if (!val) {
            val = 0;
          }
          return acc + parseInt(val);
        }, 0);
        // console.log(totalArea);

        this.options = {
          title: {
            text: `${totalArea}亩`,
            subtext: `塘口总面积\n`,
            x: "39%",
            y: "41%",
            textAlign: "center",
            textStyle: {
              fontWeight: "bold",
              fontSize: 38,
              color: "#fff",
            },
            subtextStyle: {
              fontWeight: "normal",
              fontSize: 18,
              color: "#fff",
            },
          },
          legend: {
            icon: "circle", // 设置图标为圆形
            orient: "vertical",
            right: "5%",
            top: "center",
            // width: 140,
            padding: [0, 0, 15, 0],
            data: ponds.map((pond) => pond.name),
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} 亩 ({d}%)",
          },
          series: [
            {
              name: "鱼塘面积",
              type: "pie",
              radius: ["50%", "70%"],
              center: ["40%", "50%"],
              left: "10%",
              right: "15%",
              data: ponds,
              label: {
                show: false,
              },
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
              },
            },
          ],
        };
      } catch (error) {
        // console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.title {
  color: #516b91;
  position: absolute;
  top: 4%;
  left: 4%;
  width: 123px;
  font-size: 17px;
  font-weight: bold;
}
</style>
