<template>
  <div class="">
    <!-- 各类告警统计 -->
    <dv-border-box-12>
      <e-chart
        :options="options"
        id="box"
        height="340px"
        width="100%"
      ></e-chart>
    </dv-border-box-12>
  </div>
</template>

<script>
import EChart from "@/common/echart/index.vue";

const dataArr = [
  { name: "掉电", value: 3, color: "#2d8cf0" },
  { name: "缺相", value: 1, color: "#19be6b" },
  { name: "过载", value: 7, color: "#ff9900" },
  { name: "过压", value: 6, color: "#E46CBB" },
  { name: "功率", value: 9, color: "#9A66E4" },
  { name: "老化", value: 11, color: "#ed3f14" },
  { name: "低氧", value: 5, color: "#C7B446" },
  { name: "pH值", value: 7.6, color: "#89AC76" },
  { name: "水温", value: 12, color: "#FF2301" },
];
const half = Math.ceil(dataArr.length / 2);
const leftData = dataArr.slice(0, half);
const rightData = dataArr.slice(half).map((item) => {
  return { name: item.name, value: -item.value, color: item.color };
});

const legendOffsetX = 20;
const legendOffsetY = 100;

const customLegend = dataArr.map((item, idx) => ({
  type: "rect",
  shape: {
    x: legendOffsetX,
    y: legendOffsetY + idx * 20,
    width: 10,
    height: 10,
  },
  style: {
    fill: item.color,
  },
}));

const customLegendText = dataArr.map((item, idx) => ({
  type: "text",
  style: {
    text: item.name,
    x: 20 + legendOffsetX,
    y: legendOffsetY + idx * 20,
    fill: item.color,
    fontSize: 12,
  },
}));

const option = {
  title: {
    text: "各类数据",
    top: "3%",
    left: "3%",
  },
  legend: {
    show: false,
  },
  graphic: customLegend.concat(customLegendText),
  yAxis: {
    type: "value",
    axisLabel: {
      show: true, // 修改为true来显示标签
      formatter: function (value) {
        return Math.abs(value); // 显示绝对值
      },
    },
  },
  xAxis: {
    type: "category",
    axisLabel: {
      show: false, // 隐藏y轴的标签
    },
  },
  grid: {
    left: "28%",
    right: "12%",
    bottom: "12%",
    top: "15%",
  },
  series: [
    {
      name: "Top",
      type: "bar",
      stack: "total",
      barWidth: 30, // 设置柱子宽度
      // barGap: "15%", // 设置柱子间距
      itemStyle: {
        color: function (params) {
          return leftData[params.dataIndex].color;
        },
      },
      label: {
        show: true,
        position: "inside",
        fontSize: 16, // 设置字体大小为16
        formatter: function (params) {
          return Math.abs(params.value);
        },
      },
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return `${leftData[params.dataIndex].name}: ${Math.abs(
            params.value
          )}`;
        },
      },
      data: leftData.map((item) => item.value),
    },
    {
      name: "Bottom",
      type: "bar",
      stack: "total",
      barWidth: 30, // 设置柱子宽度
      // barGap: "15%", // 设置柱子间距
      itemStyle: {
        color: function (params) {
          return rightData[params.dataIndex].color;
        },
      },
      label: {
        show: true,
        position: "inside",
        fontSize: 16, // 设置字体大小为16
        formatter: function (params) {
          return Math.abs(params.value);
        },
      },
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          return `${rightData[params.dataIndex].name}: ${Math.abs(
            params.value
          )}`;
        },
      },
      data: rightData.map((item) => item.value),
    },
  ],
};

export default {
  components: { EChart },
  data() {
    return {
      options: option,
    };
  },
};
</script>
