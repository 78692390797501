<template>
  <div class="">
    <dv-border-box-12>
      <!-- <div class="">动态告警列表</div> -->
      <dv-scroll-board
        :config="scrollData"
        style="width: 100%; height: 350px"
      />
    </dv-border-box-12>
  </div>
</template>

<script>
import { getMachineAlertApi } from "@/api/modules/operation";

export default {
  data() {
    return {
      interval: null, // 定时器
      scrollData: {},
    };
  },
  mounted() {
    this.fetchData(); // 立即执行一次
    this.interval = setInterval(this.fetchData, 15000); // 然后每5秒执行一次
  },
  beforeDestroy() {
    clearInterval(this.interval); // 清除定时器
  },
  methods: {
    async fetchData() {
      try {
        const res = await getMachineAlertApi();
        // console.log("机电水质报警/动态告警列表 (轮询 5s) ", res);
        // 只获取data里面的前5条
        const firstFive = res.data.slice(0, 5);
        this.scrollData = {
          header: ["对象", "告警", "日期"],
          data: firstFive.map((item) => {
            return [
              item.deviceName,
              item.typeName,
              item.createTime.slice(0, 16),
            ];
          }),
        };
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
