<template>
  <div id="index" ref="appRef" class="">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div class="host-body" v-else>
        <big-header></big-header>
        <div class="host-body-box">
          <div class="top-row">
            <div class="top-row--item">
              <div class="top-row--item__img-wpr">
                <img src="../../assets/images/base-info.png" alt="" />
              </div>
              <div class="top-row--item__content">
                <div class="top-row--item__content__text">基地情况</div>
                <div class="top-row--item__content__amount glowing-text">
                  {{ bannerData.area }}/{{ bannerData.fishpondCount }}
                </div>
              </div>
            </div>
            <div class="top-row--item">
              <div class="top-row--item__img-wpr">
                <img src="../../assets/images/alert.png" alt="" />
              </div>
              <div class="top-row--item__content">
                <div class="top-row--item__content__text">月报警量</div>
                <div class="top-row--item__content__amount glowing-text">
                  {{ bannerData.monthAlertCount }}
                  <span class="subtext">&nbsp;次</span>
                </div>
              </div>
            </div>
            <div class="top-row--item">
              <div class="top-row--item__img-wpr">
                <img src="../../assets/images/daily.png" alt="" />
              </div>
              <div class="top-row--item__content">
                <div class="top-row--item__content__text">日预警量</div>
                <div class="top-row--item__content__amount glowing-text">
                  {{ bannerData.dayAlertCount }}
                  <span class="subtext">&nbsp;次</span>
                </div>
              </div>
            </div>
            <div class="top-row--item">
              <div class="top-row--item__img-wpr">
                <img src="../../assets/images/maintainance.png" alt="" />
              </div>
              <div class="top-row--item__content">
                <div class="top-row--item__content__text">机电报警</div>
                <div class="top-row--item__content__amount glowing-text">
                  {{ bannerData.electromechanical }}
                  <span class="subtext">&nbsp;次</span>
                </div>
              </div>
            </div>
            <div class="top-row--item">
              <div class="top-row--item__img-wpr">
                <img src="../../assets/images/water.png" alt="" />
              </div>
              <div class="top-row--item__content">
                <div class="top-row--item__content__text">水质报警</div>
                <div class="top-row--item__content__amount glowing-text">
                  {{ bannerData.waterQuality }}
                  <span class="subtext">&nbsp;次</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row-boxes">
            <pond-area-stat></pond-area-stat>
            <pond-status></pond-status>
            <water-quality-stat></water-quality-stat>
          </div>
          <div class="row-boxes">
            <machine-in-pond></machine-in-pond>
            <water-status></water-status>
            <scroll-alert></scroll-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";
import BigHeader from "../../layout/header.vue";
import PondAreaStat from "./components/PondAreaStat.vue";
import WaterQualityStat from "./components/WaterQualityStat.vue";
import MachineInPond from "./components/MachineInPond.vue";
import WaterStatus from "./components/WaterStatus.vue";
import ScrollAlert from "./components/ScrollAlert.vue";
import PondStatus from "./components/PondStatus.vue";
import { getOperationDataApi } from "@/api/modules/operation";

// import WebSocketClient from "@/utils/websocket";

export default {
  mixins: [drawMixin],
  components: {
    BigHeader,
    PondAreaStat,
    WaterQualityStat,
    MachineInPond,
    WaterStatus,
    ScrollAlert,
    PondStatus,
  },
  data() {
    return {
      loading: true,
      decorationColor: ["#568aea", "#000000"],
      webSocketConnect: null,
      bannerData: {
        area: 0,
        fishpondCount: 0,
        monthAlertCount: 0,
        dayAlertCount: 0,
        electromechanical: 0,
        waterQuality: 0,
      },
    };
  },
  mounted() {
    this.cancelLoading();
    this.initData();
    this.initData(); // 立即执行一次
    this.interval = setInterval(this.initData, 10000); // 然后每10秒执行一次
    // this.webSocketConnect = new WebSocketClient();
    // this.webSocketConnect.connect();
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    async initData() {
      try {
        const res = await getOperationDataApi();
        // console.log("获取运营一张网上方5个数据的接口: ", res);
        this.bannerData = res.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/index.scss";
.row-boxes {
  width: 1920px;
  padding: 0 40px;
  display: grid;
  grid-template-columns: 480px 840px 480px;
  justify-content: space-between;
  margin-bottom: 35px;
}
.host-body-box {
  // margin-top: 150px;
  display: flex;
  flex-direction: column;
}
.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 158px 58px 158px;
  padding-top: 55px;
  &--item {
    display: flex;
    &__img-wpr {
      width: 85px;
      height: 100%;
      margin-right: 36px;
      & img {
        width: 100%;
        object-fit: contain;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__text {
        font-weight: normal;
        font-size: 18.65px;
        color: #62a2cd;
      }
      &__amount {
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url(../../assets/images/kuang.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 18px;
        height: 44px;
        width: 100px;
        font-weight: bold;
        font-size: 25.26px;
        & .subtext {
          font-weight: normal;
          font-size: 16px;
          color: #24aeff;
        }
      }
    }
  }
}
</style>
