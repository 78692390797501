import http from "../index.js";
// const userId = localStorage.getItem("userId");
// const userId = 220; //
export const userId = 217; // 华哥体验账号
// export const userId = 267; // JINGHAO 账号

/**
 * 获取运营一张网上方5个数据的接口
 * @returns
 */
export const getOperationDataApi = () => {
  const url = `/web/fullScreen/${userId}/banner`;
  return http.get(url);
};

/**
 * 获取鱼塘状态数据  - 塘口状态(需轮询)
 * @returns
 */
export const getPondStatusApi = () => {
  const url = `/web/fullScreen/${userId}/fishpondStatus`;
  return http.get(url);
};

/**
 * 获取鱼塘状态数据2  - 塘口状态(需轮询) 获取所有的鱼塘以及下面的机器信息
 * @returns
 */
export const getAllPondApi = () => {
  const url = `/web/fishpond/list`;
  return http.get(url);
};

/**
 * 机电水质报警/动态告警列表
 */

export const getMachineAlertApi = () => {
  const url = `/web/fullScreen/${userId}/plumber`;
  return http.get(url);
};

/**
 * 基塘-塘口统计
 */

export const getPondMachineCount = () => {
  const url = `web/fullScreen/${userId}/statisticsFishpond`;
  return http.get(url);
};

/**
 *  各测点数据
 */
export const getAllPondMachineStatus = () => {
  const url = `/web/fullScreen/${userId}/statisticsFishpondData`;
  return http.get(url);
};
/**
 *  塘口--渔机种类
 */
export const getPondMachines = () => {
  const url = `/web/fullScreen/${userId}/statisticsFishpondType`;
  return http.get(url);
};
